
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <form @submit.prevent="updateCustomers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_username" type="text" :label="$store.getters.language.data.customers.customer_username" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_phone" type="textarea" :label="$store.getters.language.data.customers.customer_phone" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_phone_2" type="textarea" :label="$store.getters.language.data.customers.customer_phone_2" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="['slemani','hawler','karkuk','dhok','zaxo','baghdad','darbandixan','halabja','rania','others']" v-model="customers.customer_city" dense  filled outlined  item-text="customer_city"
                                    item-value="customer_city" :return-object="false" :label="$store.getters.language.data.customers.customer_city">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-text-field v-model="customers.customer_address" type="text" :label="$store.getters.language.data.customers.customer_address" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="['elly_online_store','shein_store_iraq','pages']" v-model="customers.customer_type" dense  filled outlined  item-text="customer_type"
                                    item-value="customer_type" :return-object="false" :label="$store.getters.language.data.customers.customer_type">
                                </v-select>
                        </v-flex>
                        <v-flex xs12 lg4 xl4 md4 sm4>
                                <v-select class="mx-1" clearable  :items="['active','blocked']" v-model="customers.customer_status" dense  filled outlined  item-text="customer_status"
                                    item-value="customer_status" :return-object="false" :label="$store.getters.language.data.customers.customer_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="customers.user_id" dense  filled outlined  item-text="user_username"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.customers.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/customers.request.js'
    export default {
        data() {
            return {
                customers: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    users(){
                        return this.$store.getters.users_list
                    },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneCustomers()
        },
        methods: {
            getOneCustomers() {
                this.loading = true
                requests.getOneCustomers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.customers = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Customers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Customers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateCustomers() {
                this.loading = true
                this.customers.user_id = this.$store.getters.user.user_id
                requests.updateCustomers(this.id,this.customers).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Customers Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    